/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
::-webkit-scrollbar-thumb {
  background: rgb(157, 162, 166, 0.5) !important;
  border-radius: 5px;
}

.container {
  max-width: 1300px;
}

:root {
  --primary-color: #00A884; /* Redefine the global color variable for a dark theme */
}

-------- html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
* {
  font-family: 'Poppins';
  font-style: normal;
}
i {
  font-family: inherit;
  font-style: italic;
}
#main-wrapper,
main {
  height: 100vh;
  width: 100%;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.px-435 {
  padding-left: 435px !important;
}
.px-80 {
  padding-left: 80px !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.lh-18 {
  line-height: 18px;
}
.lh-21 {
  line-height: 21px;
}
.lh-24 {
  line-height: 24px;
}
.lh-30 {
  line-height: 30px;
}
.slick-arrow {
  display: none !important;
}
#small-screen {
  display: none;
}
form .text-danger {
  font: 10px "Poppins", sans-serif;
  color: #dc3545;
  font-weight: 400;
  margin-top: 5px;
  margin-left: 2.2rem;
}
.inline-form {
  position: relative;
}
.required-msg {
  position: absolute;
  left: 5.5rem;
  bottom: -16px;
}
.alert-dismissible .btn-close:focus {
  outline: none;
  box-shadow: none;
}

/* Mat Icon */
.mat-icon {
  overflow: inherit !important;
}

/* Radio button */
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--primary-color) !important;
  --mdc-radio-selected-hover-icon-color: var(--primary-color) !important;
  --mdc-radio-selected-icon-color: var(--primary-color) !important;
  --mdc-radio-selected-pressed-icon-color: var(--primary-color) !important;
  --mat-mdc-radio-checked-ripple-color: var(--primary-color) !important;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: var(--primary-color) !important;
  --mdc-checkbox-unselected-pressed-icon-color: var(--primary-color);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color) !important;
  --mdc-checkbox-selected-icon-color: var(--primary-color) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color) !important;
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: var(--primary-color) !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle, .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--primary-color) !important;
}

.mat-mdc-radio-button.mat-accent .mdc-form-field {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #54656F;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
}
.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-form-field {
  color: #111b21;
  font-weight: 500;
}
.toastr-link{
  color: #FFFFFF !important;
}
#toast-container .ngx-toastr {
  background-color: #FFFFFF;
  background-image: none;
  padding: 15px 15px 15px 75px;
  /* box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
}
#toast-container button {
  display: none;
}
#toast-container .toast-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #111B21;
}
#toast-container .toast-message {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #72838D;
}
#toast-container .user-icon {
  width: 50px;
  height: 50px;
  background: #DFE5E7;
  display: flex;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #A2B0B8;
  top: calc(50% - 25px);
  left: 15px;
  position: absolute;
}
.mat-tooltip {
  font-size: 12px !important;
}

@media (min-width: 1440) {
  .container {
    max-width: 1400px !important;
  }
}